import * as React from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import ServiceCard from './ServiceCard.tsx';
import showcookingImage from '../../../assets/servicios/showCooking.webp';
import claseSushi from '../../../assets/servicios/claseSushi.jpg';
import platosdecorativos from '../../../assets/servicios/platosdecorativos.jpg';
import tartaCumple from '../../../assets/servicios/tartaCumple.jpg';
const services = [
  {
    image: showcookingImage,
    title: 'Showcooking',
    description: 'Sushiman preparando y sirviendo en vivo, creando una experiencia única.'
  },
  {
    image: platosdecorativos,
    title: 'Platos decorativos',
    description: 'Sushi en gran escala, ideal para cualquier evento o presentación.'
  },
  {
    image: claseSushi,
    title: 'Talleres de sushi',
    description: 'Prueba la experiencia de elaborar el sushi con ayuda del sushiman'
  },
  {
    image: tartaCumple,
    title: 'Tarta de cumpleaños',
    description: 'Si quieres sorprender con algo diferente y especial aquí tienes lo que buscas'
  },
];

const Services: React.FC<{ colorText: string }> = ({ colorText }) => {
  return (
    <Box
      sx={{ minHeight: 200, display: "flex", justifyContent: "center", alignItems: "center", paddingTop: '45px' }}
    >
      <Card sx={{
        maxWidth: "100%", boxShadow: 'none', p: 1, borderRadius: "0", backgroundColor: "transparent", marginTop: '30px'
      }}>
        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 3 }} >
          <Box sx={{ flex: 1 }}>
            <CardContent sx={{ padding: 3 }}>
              <Typography variant="h5" gutterBottom textAlign={'center'}>
                <span style={{ color: colorText, fontWeight: "bold" }}>
                  NUESTROS SERVICIOS
                </span>
              </Typography>
            </CardContent>
            <Grid container spacing={3} justifyContent={'center'}>
              {services.map((service, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <ServiceCard
                    image={service.image}
                    title={service.title}
                    description={service.description}
                    colorText={colorText}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default Services;
